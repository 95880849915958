import { RadioButton, RadioButtonGroup } from '@capturi/ui-components'
import { Box, Flex, Spacer, useTheme } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'
import { Topic } from 'features/aiTopics'
import React, { useCallback, useMemo, useState } from 'react'

import {
  WordCloud,
  WordCloudItem,
} from '../../../components/WordCloud/WordCloud'

type TopicsWordCloudProps = {
  initialWordCloud?: TopicsWordCloudType
  topics: Topic[]
  onSelectTopic?: (topic: Topic) => void
  onChangeTopicsWordCloudType?: (
    topicsWordCloudType: TopicsWordCloudType,
  ) => void
}

export enum TopicsWordCloudType {
  Trend = 'Trend',
  Conversations = 'Conversations',
}

const TopicsWordCloud: React.FC<TopicsWordCloudProps> = ({
  initialWordCloud = TopicsWordCloudType.Trend,
  topics,
  onSelectTopic,
  onChangeTopicsWordCloudType,
}) => {
  const theme = useTheme()

  const [wordCloudType, setWordCloudType] =
    useState<TopicsWordCloudType>(initialWordCloud)

  const slicedTopics = useMemo(
    () =>
      topics
        .sort((t1, t2) => {
          return wordCloudType === TopicsWordCloudType.Trend
            ? Math.abs(t2.trend) - Math.abs(t1.trend)
            : t2.hits - t1.hits
        })
        .slice(0, 25),
    [topics, wordCloudType],
  )

  const wordCloudItems = useMemo(
    () =>
      slicedTopics.map((topic): WordCloudItem => {
        return wordCloudType === TopicsWordCloudType.Trend
          ? { name: topic.name, value: topic.trend }
          : { name: topic.name, value: topic.hits }
      }),
    [slicedTopics, wordCloudType],
  )

  const wordCloudItemToValueMap = useMemo(
    () =>
      wordCloudItems.reduce<Record<string, number>>((acc, item) => {
        acc[item.name] = item.value
        return acc
      }, {}),
    [wordCloudItems],
  )

  const handleOnClick = useCallback(
    (item: WordCloudItem) => {
      const topic = slicedTopics.find((topic) => topic.name === item.name)
      if (topic == null) return
      onSelectTopic?.(topic)
    },
    [slicedTopics, onSelectTopic],
  )

  const handleWordCloudTypeChange = useCallback(
    (nextValue: TopicsWordCloudType) => {
      setWordCloudType(nextValue)
      onChangeTopicsWordCloudType?.(nextValue)
    },
    [onChangeTopicsWordCloudType],
  )

  const fill = useCallback(
    (name: string) => {
      if (wordCloudType === TopicsWordCloudType.Conversations) {
        return 'url(#volume-fill-gradient)'
      }

      const value = wordCloudItemToValueMap[name]
      if (value < 0) return theme.colors.danger
      return theme.colors.success
    },
    [
      wordCloudType,
      wordCloudItemToValueMap,
      theme.colors.danger,
      theme.colors.success,
    ],
  )

  return (
    <Box
      bg="accents.lightBackground.default"
      borderColor="gray.200"
      borderWidth={1}
      borderRadius="lg"
      boxShadow="md"
      mb={5}
      width="100%"
    >
      <Flex mr={6} ml={6} mt={4} alignItems="baseline">
        <Spacer />
        <RadioButtonGroup
          name="wordCloudType"
          onChange={handleWordCloudTypeChange}
          value={wordCloudType}
        >
          <RadioButton value="Trend">
            <Trans>Trend</Trans>
          </RadioButton>
          <RadioButton value="Conversations">
            <Trans>Conversations</Trans>
          </RadioButton>
        </RadioButtonGroup>
      </Flex>
      <Box
        w="100%"
        h="223px"
        mb={6}
        overflow="hidden"
        css={css`
          text:hover {
            cursor: pointer;
            filter: brightness(85%);
          }
        `}
      >
        {wordCloudType === TopicsWordCloudType.Conversations && (
          <svg width="0" height="0" style={{ position: 'absolute' }}>
            <defs>
              <linearGradient
                id="volume-fill-gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#27829C" />
                <stop offset="33%" stopColor="#1B4963" />
                <stop offset="66%" stopColor="#705257" />
                <stop offset="100%" stopColor="#0A454D" />
              </linearGradient>
            </defs>
          </svg>
        )}
        <WordCloud
          items={wordCloudItems}
          fill={fill}
          handleClick={handleOnClick}
        />
      </Box>
    </Box>
  )
}

export default TopicsWordCloud
